import React from "react";
import { NavLink } from "react-router-dom";
import ASB01 from '../assets/ASB01.webp';
import ASB02 from '../assets/ASB02.webp';
import ASB03 from '../assets/ASB03.webp';
import SS01 from '../assets/SS01.webp';
import SS02 from '../assets/SS02.webp';
import SE01 from '../assets/SE01.webp';
import SE02 from '../assets/SE02.webp';

const Home = () => {
    return (
        <main>
            <section className="home--title-section large-spacer">
                <h1 className="letters">
                    <span>A</span>
                    <span>L</span>
                    <span>E</span>
                    <span>X</span>
                    <span>A</span>
                    <span>N</span>
                    <span>D</span>
                    <span>E</span>
                    <span>R</span>
                    <span style={{opacity: 0.5}}>L</span>
                    <span style={{opacity: 0.5}}>E</span>
                    <span style={{opacity: 0.5}}>V</span>
                    <span style={{opacity: 0.5}}>I</span>
                    <span>T</span>
                    <span>O</span>
                    <span>W</span>
                    <span>N</span>
                    <span>S</span>
                    <span>O</span>
                    <span>N</span>
                </h1>
                <h1 className="letters small-spacer">
                    <span>D</span>
                    <span>E</span>
                    <span>S</span>
                    <span>I</span>
                    <span>G</span>
                    <span>N</span>
                    <span>E</span>
                    <span>R</span>
                    <span style={{opacity: 0.5}}>A</span>
                    <span style={{opacity: 0.5}}>N</span>
                    <span style={{opacity: 0.5}}>D</span>
                    <span>D</span>
                    <span>E</span>
                    <span>V</span>
                    <span>E</span>
                    <span>L</span>
                    <span>O</span>
                    <span>P</span>
                    <span>E</span>
                    <span>R</span>
                </h1>
            </section>
            <section className="work-section large-spacer">
                <div className="work-grid-title">
                    <h2>WEBSITE REDESIGN FOR ANOTHER STORY</h2>
                    <div className="line"></div>
                    <NavLink to='/work/anotherstory'>INFO</NavLink>
                </div>
                <div className="work-grid small-spacer">
                    <img src={ASB01} alt=""/>
                    <img src={ASB02} alt=""/>
                    <img src={ASB03} alt=""/>
                </div>
                <div className="work-grid-title large-spacer">
                    <h2>SOFT SOUND: A WEB BASED SYNTHESIZER</h2>
                    <div className="line"></div>
                    <a target="_blank" href="https://github.com/alextownson/soft-sound">INFO</a>
                </div>
                <div className="work-grid small-spacer">
                    <img src={SS01} alt=""/>
                    <img src={SS02} alt=""/>
                    <iframe src="https://player.vimeo.com/video/559910995?h=33998873f8"  frameBorder="0" allow="fullscreen; picture-in-picture" title="Soft Sound Motion Graphic"></iframe>
                    </div>
                <div className="work-grid-title large-spacer">
                    <h2>SPACE EXPLORERS: A WEB BASED GAME</h2>
                    <div className="line"></div>
                    <a target="_blank" href="https://github.com/alextownson/space-explorers">INFO</a>
                </div>
                <div className="work-grid small-spacer">
                    <img src={SE01} alt=""/>
                    <img src={SE02} alt=""/>
                    <iframe src="https://player.vimeo.com/video/803889493?h=2923c59071" frameBorder="0" allow="fullscreen; picture-in-picture" title="Space Explorers Demo"></iframe>
                </div> 
            </section>
        </main>
    )
}

export default Home;