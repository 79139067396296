import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import vimeo from '../assets/vimeo.svg';
import github from '../assets/github.svg';

const Footer = () => {
    return(
        <footer className="footer">
            <div id="footer--container">
            <a href="mailto:hello@alextownson.ca">HELLO@ALEXTOWNSON.CA</a>
            <p><strong>TORONTO, CANADA</strong></p>
            <div id="footer--logos">
                <a target="_blank" href="https://github.com/alextownson"><img src={github} alt="GitHub logo"/></a>
                <a target="_blank" href="https://vimeo.com/alextownson"><img src={vimeo} alt="Vimeo logo"/></a>
            </div>
            </div>
        </footer>
    )
}

export default Footer;