import React from "react";
import { NavLink } from 'react-router-dom';
import TEM01 from '../assets/TEM01.webp'
import TEM02 from '../assets/TEM02.webp'
import TEM03 from '../assets/TEM03.webp'

export default function TownEvents() {
    return (
        <main>
            <section className="work-section large-spacer">
                <h1><strong><NavLink to='/work'>WORK</NavLink> &nbsp; / &nbsp;</strong>TOWN EVENTS MANAGEMENT</h1>
                <div className="work-grid medium-spacer">
                    <img src={TEM01} alt=""/>
                    <img src={TEM02} alt=""/>
                    <img src={TEM03} alt=""/>
                </div>
                <div className="medium-spacer">
                <p>
                Town Events Management provides association management services which include organizing conferences, meetings, and events. I was hired in September 2022 to redesign their website. The goal of the redesign was to simplify the website to include more general information as they are no longer actively seeking clients.    
                <br />
                <br /> 
                The pre-existing website had been designed many years ago using a tool my client no longer had access to. It had not been updated since then and contained content which was no longer relevant. This led to redesigning and developing the website from scratch. I created a project plan and in collaboration with the client decided which content should be kept. I then created wireframes, transferred the existing domain name to a new host, selected an appropriate static hosting service, and coded the new website using HTML, CSS, and JavaScript.
                </p>
            </div>
            </section>
        </main>
    )
}