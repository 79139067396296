import React from "react";
import { NavLink } from 'react-router-dom';
import ASB01 from '../assets/ASB01.webp';
import ASB02 from '../assets/ASB02.webp';
import ASB03 from '../assets/ASB03.webp';
import SS01 from '../assets/SS01.webp';
import SS02 from '../assets/SS02.webp';
import SE01 from '../assets/SE01.webp';
import SE02 from '../assets/SE02.webp';
import RFTLOI01 from '../assets/RFTLOI01.webp'
import RFTLOI02 from '../assets/RFTLOI02.webp'
import RFTLOI03 from '../assets/RFTLOI03.webp'
import TEM01 from '../assets/TEM01.webp'
import TEM02 from '../assets/TEM02.webp'
import TEM03 from '../assets/TEM03.webp'

export default function Work(){
    return(
        <main>
            <section className="work-section large-spacer">
                <h1>SELECTED WORK</h1>
                <div className="work-grid-title medium-spacer">
                    <h2>WEBSITE REDESIGN FOR ANOTHER STORY</h2>
                    <div className="line"></div>
                    <NavLink to='/work/anotherstory'>INFO</NavLink>
                </div>
                <div className="work-grid small-spacer">
                    <img src={ASB01} alt=""/>
                    <img src={ASB02} alt=""/>
                    <img src={ASB03} alt=""/>
                </div>
                <div className="work-grid-title medium-spacer">
                    <h2>WEBSITE REDESIGN FOR RFTLOI</h2>
                    <div className="line"></div>
                    <NavLink to='/work/rftloi'>INFO</NavLink>
                </div>
                <div className="work-grid small-spacer">
                    <img src={RFTLOI01} alt=""/>
                    <img src={RFTLOI02} alt=""/>
                    <img src={RFTLOI03} alt=""/>
                </div>
                <div className="work-grid-title medium-spacer">
                    <h2>WEBSITE REDESIGN FOR TEM</h2>
                    <div className="line"></div>
                    <NavLink to='/work/townevents'>INFO</NavLink>
                </div>
                <div className="work-grid small-spacer">
                    <img src={TEM01} alt=""/>
                    <img src={TEM02} alt=""/>
                    <img src={TEM03} alt=""/>
                </div>
                <div className="work-grid-title large-spacer">
                    <h2>SOFT SOUND: A WEB BASED SYNTHESIZER</h2>
                    <div className="line"></div>
                    <a target="_blank" href="https://github.com/alextownson/soft-sound">INFO</a>
                </div>
                <div className="work-grid small-spacer">
                    <img src={SS01} alt=""/>
                    <img src={SS02} alt=""/>
                    <iframe src="https://player.vimeo.com/video/559910995?h=33998873f8"  frameBorder="0" allow="fullscreen; picture-in-picture"></iframe>
                    </div>
                <div className="work-grid-title large-spacer">
                    <h2>SPACE EXPLORERS: A WEB BASED GAME</h2>
                    <div className="line"></div>
                    <a target="_blank" href="https://github.com/alextownson/space-explorers">INFO</a>
                </div>
                <div className="work-grid small-spacer">
                    <img src={SE01} alt=""/>
                    <img src={SE02} alt=""/>
                    <iframe src="https://player.vimeo.com/video/803889493?h=2923c59071" frameBorder="0" allow="fullscreen; picture-in-picture"></iframe>
                </div> 
            </section>
        </main>
    )
}
