import React from "react";
import atownson from "../assets/atownson.webp";

export default function About() {
    return(
        <main>
            <section>
                <h1 className="large-spacer">ABOUT</h1>
                <img className="about-photo medium-spacer"  src={atownson} />
                <h2 className="medium-spacer">ALEXANDER LEVI TOWNSON</h2>
                <div className="line x-small-spacer"></div>
                <div className="about-section-grid small-spacer">
                    <div className="about-description">
                        <p>
                            While studying graphic design at OCAD University, I became incredibly fascinated with the intersection of design and technology. This led me to focus my studies on UI design and to supplement my degree by learning front-end development. Upon graduating with distinction, I’ve continued my education through self-directed learning and experimentation, as well as through working remotely with clients as a designer and developer. I have worked on both personal and professional projects through all stages of development – research, planning, designing, development, testing, and deployment.   
                            <br/>
                            <br/>
                            When I'm not working, I enjoy making music, riding bikes, and hanging out with my two cats.
                        </p>
                    </div>
                    <div className="about-education">
                        <h2>EDUCATION</h2>
                        <div className="about-education-grid">
                        <div>
                            <h3>Bachelor of Design</h3>
                            <p><em>OCAD University</em>, 2019 - 2023</p>
                        </div>
                        <div>
                            <h3>Front-End Engineer</h3> 
                            <p><em>Codecademy Certificate</em>, 2023</p>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}