import React from "react";
import { NavLink } from "react-router-dom";
import ASB01 from '../assets/ASB01.webp';
import ASB02 from '../assets/ASB02.webp';
import ASB03 from '../assets/ASB03.webp';

export default function AnotherStory() {
    return (
        <main>
            <section className="work-section large-spacer">
                <h1><strong><NavLink to='/work' className="work-link">WORK</NavLink> &nbsp; / &nbsp;</strong>ANOTHER STORY</h1>
                    <div className="work-grid medium-spacer"> 
                        <img src={ASB01} alt=""/>
                        <img src={ASB02} alt=""/>
                        <img src={ASB03} alt=""/>
                    </div>
                    <div className="medium-spacer">
                        <p>
                        Another Story is an independent bookshop committed to equality and diversity. They have a retail store located in Toronto, Canada, as well as a wholesale store that serves educators and operates predominantly online. I was hired in August 2023 to redesign both their retail website and their newly created wholesale website. The primary goal of the redesign was to create a cohesive digital presence and establish the wholesale website as a connected but distinct store.
                        <br />
                        <br />
                        The first problem I solved was that customers did not yet know about the wholesale website and were placing wholesale orders through the retail website. I created a simple, temporary HTML landing page that intercepts customers going to the retail website. It gives a brief explanation of both websites and allows customers to choose where they want to go. Ideally, this will not be needed in the future as awareness of the wholesale website grows. 
                        <br />
                        <br />
                        After the most urgent problem was addressed, I began working on the websites themselves. The websites lacked a cohesive design due to multiple staff members contributing content. The wholesale website also lacked an intuitive structure. The bulk of their curated booklists were hidden behind multiple menus making them difficult to access. 
                        <br />
                        <br />
                        For both websites, I had to find a balance between custom HTML and CSS components and no-code components. The CMS was limited in what it could offer but on the other hand, the website needed to be maintainable by staff who don’t have design or development knowledge. In collaboration with the client, I opted to customize components that were not going to be changed often. On the wholesale website, I restructured the website through research about how their customers shop for books. I organized the curated booklists by grade level, gave each its own colour scheme, and added navigation links to the main menu.
                        </p>
                    </div>
            </section>
        </main>
    )
}