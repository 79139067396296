import React from "react"
import { NavLink } from "react-router-dom";
import RFTLOI01 from '../assets/RFTLOI01.webp'
import RFTLOI02 from '../assets/RFTLOI02.webp'
import RFTLOI03 from '../assets/RFTLOI03.webp'

export default function RFTLOI() {
    return (
        <main>
            <section className="work-section large-spacer">
                <h1><strong><NavLink to='/work'>WORK</NavLink> &nbsp; / &nbsp;</strong>READING FOR THE LOVE OF IT</h1>
                <div className="work-grid medium-spacer">
                    <img src={RFTLOI01} alt=""/>
                    <img src={RFTLOI02} alt=""/>
                    <img src={RFTLOI03} alt=""/>
                </div>
                <div className="medium-spacer">
                    <p>
                    Reading for the Love of It is an annual literacy conference run by the not-for-profit East York-Scarborough Reading Association. I was hired in June 2023 to redesign/migrate the conference website to WordPress. The primary goals of the redesign were to refresh the longstanding website design and to make maintaining the website accessible to staff with limited design and development knowledge.
                    <br />    
                    <br />
                    The pre-existing website had been maintained by an external company but with frequent small changes required, maintaining the website had become costly. I consulted with my client and recommended moving the website to WordPress so that it could be internally managed. I assisted in creating their WordPress account, transferring their existing domain name, and began with the redesign. 
                    <br />
                    <br />
                    Much of the text content for their website existed as PDF's which were then displayed as images. These were being designed externally. Where possible, I recommended and subsequently created that content directly on the website. This allowed for better accessibility because images were no longer being used in place of text. It also saved the association additional money. Their newsletter, for example, existed as a PDF document because it used to be printed and mailed. I used the blog functionality of WordPress to create a digital version of their newsletter which can then be subscribed to. 
                    </p>
                </div>
            </section>
        </main>
    )
}