import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';

const Header = () => {
    const [isNavVisible, setIsNavVisible] = useState(true);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 1000px)");
        mediaQuery.addEventListener("change", handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery); 

        return () => {
            mediaQuery.removeEventListener("change", handleMediaQueryChange)
        }
    }, []);

    const handleMediaQueryChange = mediaQuery => {
        if (mediaQuery.matches){
            setIsSmallScreen(true);
            setIsNavVisible(!isNavVisible);
        } else {
            setIsSmallScreen(false);
            setIsNavVisible(true);
        }
    };

    const toggleNav = () => {
        if(isSmallScreen) {
            setIsNavVisible(!isNavVisible);
        }
    };

    return(
        <header className="header">
            <div>
            <NavLink className="logo" to='/'>ALT</NavLink>
            <button onClick={toggleNav} className="menu-button">{!isSmallScreen || isNavVisible ? "X" : "•••"}</button>
            {(!isSmallScreen || isNavVisible) && (
                <nav className="nav">
                    <NavLink onClick={toggleNav} to='/about'>ABOUT</NavLink>
                    <NavLink onClick={toggleNav} to='/work'>WORK</NavLink>
                </nav>
            )}
            </div>
        </header>
    )
}

export default Header;