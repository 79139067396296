import React from 'react';
import { Navigate, RouterProvider, createBrowserRouter} from 'react-router-dom';
import Layout from './pages/Layout';
import Home from './pages/Home';
import Work from './pages/Work';
import About from './pages/About';
import AnotherStory from './pages/AnotherStory'
import RFTLOI from './pages/RFTLOI';
import TownEvents from './pages/TownEvents';
import NoPage from './pages/NoPage';
import './main.css';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: 'work',
        element: <Work />
      },
      {
        path: 'about',
        element: <About />
      },
      {
        path: 'work/anotherstory',
        element: <AnotherStory />
      },
      {
        path: 'work/rftloi',
        element: <RFTLOI />
      },
      {
        path: 'work/townevents',
        element: <TownEvents />
      },
      {
        path: '*',
        element: <NoPage />
      }
    ]
  }]
)

const App = () => {
  return (
    <RouterProvider router={router} /> 
  )
}

export default App;