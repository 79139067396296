import React from "react";

export default function NoPage() {
    return(
        <main>
            <section className="large-spacer">
                <h1>404<br />PAGE NOT FOUND</h1>
            </section>
        </main>
    )
}